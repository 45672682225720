import React, { useLayoutEffect } from "react"

export default function App() {
  const accessToken = process.env.REACT_APP_CESIUM_ACCESS_TOKEN

  async function init() {
    const Cesium = window.Cesium
    Cesium.Ion.defaultAccessToken = accessToken

    // const viewer = new Cesium.Viewer("cesiumContainer")
    // when creating viewer use Sea level
    const viewer = new Cesium.Viewer("cesiumContainer", {
      terrainProvider: new Cesium.EllipsoidTerrainProvider(),
    })

    const resource = await Cesium.IonResource.fromAssetId(2259492)
    const dataSource = await Cesium.GeoJsonDataSource.load(resource)

    // make all polygons of different colors

    const towns = []

    const residential_poly_layers = dataSource.entities.values

    for (let i = 0; i < residential_poly_layers.length; i++) {
      const entity = residential_poly_layers[i]
      // print entity attribute names and values
      towns.push(entity.properties["_Town Name"]["_value"])
      entity.polygon.show = false
      entity.polygon.material = Cesium.Color.fromRandom({
        alpha: 0.6,
      })
    }

    await viewer.dataSources.add(dataSource)

    const tileset = viewer.scene.primitives.add(
      new Cesium.Cesium3DTileset({
        url: Cesium.IonResource.fromAssetId(2255872),
      })
    )
    const tileset_tower = viewer.scene.primitives.add(
      new Cesium.Cesium3DTileset({
        url: Cesium.IonResource.fromAssetId(2256091),
      })
    )

    const tileset_metro = viewer.scene.primitives.add(
      new Cesium.Cesium3DTileset({
        url: Cesium.IonResource.fromAssetId(2270409),
      })
    )

    await tileset.readyPromise
    await tileset_tower.readyPromise
    await tileset_metro.readyPromise

    const boundingSphere = tileset.boundingSphere

    viewer.camera.flyToBoundingSphere(boundingSphere, {
      offset: new Cesium.HeadingPitchRange(0.4, -0.15, boundingSphere.radius),
    })

    // Apply the default style if it exists
    let extras = tileset.asset.extras
    if (Cesium.defined(extras) && Cesium.defined(extras.ion) && Cesium.defined(extras.ion.defaultStyle)) {
      tileset.style = new Cesium.Cesium3DTileStyle(extras.ion.defaultStyle)
    }

    return { viewer, towns, residential_poly_layers }
  }

  useLayoutEffect(async () => {
    const { viewer, towns, residential_poly_layers } = await init()

    function get_input_boxes() {
      let final_html = ""
      for (const town of towns) {
        final_html += `<div class="symbology-item" style="display: flex; align-items: center; margin-bottom: 4px;">
        <input type="checkbox" class="polygon-checkbox" id="${town}" name="${town}" value="${town}" style="margin-left: 10px;">
        <label style="color: rgb(237, 255, 255);" for="${town}">${town}</label>
      </div>`
      }

      return final_html
    }

    // make the icon image in center

    window.$(".cesium-viewer").append(`
        <div class="symbology-icons-container">
          <div class="mc-btn cesium-button cesium-toolbar-button" id="symbology-btn">
            <img
            src="download.png"
            title="Hide / Show Layers" alt="Hide / Show Layers" style="width: 30px; height: 30px; margin: auto;"/>
          </div>
        </div>

        <div class="symbology-container">

          ${get_input_boxes()}

          <div/>
        `)

    // opacity .2s ease-out,transform .2s ease-out

    window.$("#symbology-btn").click(function () {
      if (window.$(".symbology-container").css("bottom") === "90px") {
        window.$(".symbology-container").css({ "pointer-events": "none", bottom: "-100%", opacity: 0 })
      } else {
        window.$(".symbology-container").css({ "pointer-events": "auto", bottom: "90px", opacity: 1 })
      }
    })

    window.$(".polygon-checkbox").change(function (e) {
      const town = e.target.value
      for (let i = 0; i < residential_poly_layers.length; i++) {
        const entity = residential_poly_layers[i]
        if (entity.properties["_Town Name"]["_value"] === town) {
          entity.polygon.show = e.target.checked
        }
      }
    })

    return () => {
      viewer.destroy()
    }
  }, [])

  // async function uploadData() {
  //   const assetFile = document.getElementById("asset-input").files[0]

  //   if (!assetFile) {
  //     console.log("No file selected")
  //     return
  //   }

  //   const postBody = {
  //     name: "test-asset",
  //     description: "test-asset",
  //     type: "3DTILES",
  //     options: {
  //       sourceType: "CITYGML",
  //       clampToTerrain: true,
  //       baseTerrainId: 1,
  //     },
  //   }

  //   const response = await fetch("https://api.cesium.com/v1/assets", {
  //     method: "POST",
  //     body: JSON.stringify(postBody),
  //     headers: {
  //       Authorization: "Bearer " + accessToken,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })

  //   const data = await response.json()

  //   if (response.ok) {
  //     console.log(data)

  //     const uploadLocation = data.uploadLocation

  //     const AWS = window.AWS

  //     const s3 = new AWS.S3({
  //       apiVersion: "2006-03-01",
  //       region: "us-east-1",
  //       signatureVersion: "v4",
  //       endpoint: uploadLocation.endpoint,
  //       credentials: new AWS.Credentials(uploadLocation.accessKey, uploadLocation.secretAccessKey, uploadLocation.sessionToken),
  //     })

  //     const r = await s3
  //       .putObject({
  //         Body: assetFile,
  //         Bucket: uploadLocation.bucket,
  //         Key: `${uploadLocation.prefix}Reichstag.zip`,
  //       })
  //       .promise()

  //     console.log(r)

  //     const onComplete = data.onComplete
  //     const rr = await fetch(onComplete.url, {
  //       method: onComplete.method,
  //       bodu: JSON.stringify(onComplete.fields),
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         Authorization: "Bearer " + accessToken,
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     })

  //     const d = await rr.json()
  //     console.log(d)
  //   } else {
  //     console.log(data)
  //   }
  // }

  return (
    <>
      <div id="cesiumContainer" className="fullSize" style={{ height: "100vh" }}></div>

      {/* <div id="toolbar"></div> */}
      {/* <input type="file" id="asset-input" style={{ position: "absolute", top: "20px", left: "20px" }} />
      <button onClick={uploadData} style={{ position: "absolute", top: "50px", left: "20px" }}>
        Upload
      </button> */}
    </>
  )
}
